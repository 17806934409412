import React from 'react';

interface Props {
  height?: number;
  width?: number;
}

function FindIcon(props: Props) {
  return (
    <svg
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 55 55"
      xmlSpace="preserve"
      fill="#419d3e"
      {...props}
    >
      <g>
        <path
          d="M54.9,34.4c-0.4-1.8-1.2-3.4-2.8-4.4c-0.8-0.5-1.7-0.9-2.5-1.3c0.7-1,0.8-2.2,0.7-3.4c-0.2-2.8-2.5-4.7-5.3-4.2
		c-0.9,0.2-1.7,0.7-2.5,1c-2.4-6.9-8.9-10.7-14.9-10.6c-7.4,0.1-12.4,4-15.2,10.8c-0.1-0.1-0.2-0.1-0.2-0.2c-1.4-1.2-3-1.4-4.7-0.7
		c-1.8,0.8-2.6,2.2-2.7,4.2c0,1.2,0.1,2.3,0.8,3.3C5.3,28.9,5.1,29,5,29c-2,0.6-3.4,1.9-4.3,3.7C-0.1,34.4,0,36.2,0,37.9
		c0,0.5,0.4,0.8,0.9,0.9c0.3,0,0.6,0,0.9,0c1.5,0,3.1,0,4.6,0c0.8,0,1.3-0.5,1.3-1.1c0-0.6-0.5-1-1.3-1c-0.6,0-1.3,0-1.9,0
		c-0.8,0-1.6,0-2.4,0c0-0.4,0-0.8,0-1.2C2.3,33.2,3.9,31.2,6,31c2-0.2,4,0,5.9,0c0.7,1.7,1.4,3.3,2.1,5.1c-0.1,0.1-0.2,0.2-0.4,0.3
		C9.8,40.2,6,44,2.2,47.8C2,48,1.8,48.3,1.6,48.5c-1.1,1.4-1.1,3.5,0,4.9c0.7,0.9,1.6,1.3,2.6,1.6c0.4,0,0.8,0,1.2,0
		c1.2-0.3,2.2-1,3-1.9c1.9-1.9,3.8-3.8,5.7-5.7c0.2-0.2,0.3-0.3,0.4-0.5c0.3-0.4,0.2-0.9-0.1-1.3c-0.4-0.4-0.9-0.4-1.3-0.2
		c-0.2,0.1-0.4,0.3-0.5,0.5c-2,2-4.1,4.1-6.1,6.1c-0.2,0.2-0.3,0.3-0.5,0.5c-1.1,0.8-2.6,0.3-2.9-1C2.7,50.6,3,50,3.5,49.5
		c3.9-3.9,7.7-7.7,11.6-11.6c0.1-0.1,0.2-0.2,0.3-0.3c4.4,4.6,9.7,6.6,15.9,5.1c6.2-1.5,10-5.7,11.8-11.9c1.7,0,3.4,0,5.1,0
		c0.4,0,0.8,0.1,1.1,0.2c2.5,0.7,3.9,3,3.5,5.7c-0.2,0-0.4,0-0.6,0c-3.1,0-6.2,0-9.2,0c-0.8,0-1.2,0.4-1.2,1c0,0.6,0.5,1.1,1.2,1.1
		c0.1,0,0.2,0,0.3,0c3.5,0,6.9,0,10.4,0c0.7,0,1.2-0.2,1.5-0.8c0-1.1,0-2.1,0-3.2C55,34.7,54.9,34.6,54.9,34.4z M11.5,27
		c-0.3,1.3-1.7,2-3,1.6c-1.1-0.4-1.6-1.2-1.6-2.7c0-1.7,1-2.8,2.5-2.8c0.2,0,0.4,0,0.6,0.1C11.5,23.7,11.9,25.2,11.5,27z M27.6,41.1
		c-7.6,0-13.8-6.2-13.8-13.7c0-7.6,6.2-13.7,13.7-13.7c7.5,0,13.7,6.2,13.8,13.7C41.3,34.8,35.1,41,27.6,41.1z M48.1,26.8
		c-0.2,1.2-1.3,2-2.5,1.9c-1.2-0.1-2.1-1.1-2.1-2.3c0-0.2,0-0.4,0-0.5c0-1.4,0.5-2.3,1.6-2.7c1.4-0.4,2.8,0.4,3.1,1.8
		C48.2,25.6,48.2,26.2,48.1,26.8z"
        />
        <path
          d="M27.6,5.8c0.6,0,1-0.5,1-1.1c0-0.6,0-1.2,0-1.8c0-0.6,0-1.2,0-1.8c0-0.6-0.4-1.1-1-1.1c-0.6,0-1.1,0.4-1.1,1
		c0,1.2,0,2.5,0,3.7C26.5,5.4,27,5.8,27.6,5.8z"
        />
        <path
          d="M20.5,7.7c0.2,0.2,0.7,0.2,1,0C21.8,7.6,22,7.2,22.2,7c-0.1-0.3-0.1-0.5-0.2-0.7c-0.7-0.8-1.5-1.5-2.3-2.3
		c-0.4-0.3-0.9-0.2-1.3,0.1c-0.4,0.4-0.5,0.9-0.1,1.3C19,6.3,19.7,7.1,20.5,7.7z"
        />
        <path
          d="M34.5,7.7c0.8-0.7,1.5-1.4,2.2-2.2c0.2-0.2,0.2-0.7,0-1C36.6,4.2,36.2,4,36,3.8c-0.3,0.1-0.5,0.1-0.7,0.2
		c-0.8,0.7-1.5,1.5-2.2,2.2c-0.3,0.3-0.2,0.9,0.1,1.3C33.6,7.9,34.2,8.1,34.5,7.7z"
        />
        <path d="M26.5,8.2c0,0.6,0.5,1.1,1,1.1c0.6,0,1.1-0.5,1.1-1c0-0.6-0.5-1.1-1-1.1C27,7.2,26.5,7.7,26.5,8.2z" />
        <path d="M16.5,42.4c-0.6,0-1.1,0.5-1.1,1c0,0.6,0.4,1.1,1,1.1c0.6,0,1.1-0.5,1.1-1C17.5,42.9,17,42.4,16.5,42.4z" />
        <path
          d="M27.5,15.7c-6.3,0-11.6,5.2-11.6,11.6c0,6.4,5.2,11.6,11.6,11.6c6.4,0,11.6-5.2,11.6-11.5C39.2,21,33.9,15.7,27.5,15.7z
		 M21.7,34.7c1.3-2.2,3.2-3.4,5.7-3.5c2.5-0.1,5,1.4,5.9,3.5C30.4,37.4,24.8,37.4,21.7,34.7z M25.3,25.5c0.2-1.1,1.2-1.9,2.4-1.8
		c1,0.1,1.9,1,2,2c0,0.2,0,0.4,0,0.6c0,1.6-0.8,2.6-2.1,2.6c-0.3,0-0.6,0-0.9-0.1C25.5,28.5,25,27.2,25.3,25.5z M34.9,33.1
		c-1-1.5-2.4-2.7-4.1-3.4c1.1-1.3,1.2-2.8,1-4.4c-0.3-2.1-2.2-3.7-4.3-3.7c-2.2,0-4,1.6-4.3,3.7c-0.2,1.6-0.1,3.1,1,4.4
		c-0.8,0.5-1.6,0.9-2.3,1.5c-0.7,0.6-1.2,1.2-1.8,1.9c-2.8-3.4-2.8-8.9,0.9-12.5c3.7-3.6,9.4-3.6,13.2,0
		C37.5,23.8,38,29.6,34.9,33.1z"
        />
      </g>
    </svg>
  );
}

FindIcon.defaultProps = {
  height: 80,
  width: 80,
};

export default FindIcon;
