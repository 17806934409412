import React from 'react';

interface Props {
  height?: number;
  width?: number;
}

function SupportIcon(props: Props) {
  return (
    <svg
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 55 55"
      xmlSpace="preserve"
      fill="#419d3e"
      {...props}
    >
      <g>
        <path
          d="M53.8,6.2c-1.3-1.4-2.7-2.7-4.1-4.1C48.7,1,47.4,0.2,45.9,0c-0.4,0-0.7,0-1.1,0c-1.1,0.2-2.2,0.6-3,1.4
		c-1.9,1.8-3.7,3.7-5.6,5.6c-0.5,0.5-0.6,1.2-0.5,1.8c0.4,1.7,2.4,2.3,3.7,1c1.2-1.1,2.3-2.3,3.4-3.4c0.1-0.1,0.3-0.3,0.5-0.4
		c0,0.2,0,0.2,0,0.3C43,11.7,41.5,16.6,38.5,21c-0.9,1.4-2.1,2.6-3.1,3.9c-0.4,0.5-0.7,1.1-0.6,1.8c0.1,0.9,0.6,1.5,1.5,1.8
		c0.9,0.3,1.7,0.1,2.2-0.6c1-1.1,1.9-2.1,2.8-3.3c3.5-4.7,5.6-10,6.2-15.8c0.1-0.8,0.1-1.7,0.2-2.6c0.2,0.2,0.3,0.3,0.4,0.4
		c1,1,2,2,3,3c1.5,1.5,3.1,1.2,3.9-0.7c0,0,0,0,0,0c0-0.3,0-0.6,0-1C54.6,7.3,54.3,6.7,53.8,6.2z"
        />
        <path
          d="M29.8,32.6c-0.2-1-0.9-1.7-1.9-1.8c-0.5,0-1,0.1-1.4,0.3c-3.8,1.7-7.8,2.4-11.9,2.4c-4,0-7.9,0-11.9,0
		c-1.3,0-2.2,0.4-2.7,1.7c0,0.3,0,0.6,0,0.9c0.4,1.3,1.4,1.7,2.7,1.7c4,0,8,0,12,0c2.8,0,5.6-0.2,8.2-1c1.9-0.5,3.7-1.2,5.6-1.9
		C29.5,34.5,30,33.5,29.8,32.6z"
        />
        <path
          d="M48.2,28.6c0-0.2,0-0.5,0-0.7c-0.1-1.4-1.5-2.3-2.8-1.8c-0.9,0.3-1.5,1.1-1.5,2.3c0,8,0,15.9,0,23.9c0,1.3,0.4,2.2,1.7,2.7
		c0.3,0,0.6,0,0.9,0c1.3-0.4,1.7-1.4,1.7-2.7C48.2,44.4,48.2,36.5,48.2,28.6z"
        />
        <path
          d="M36.5,34.9c-0.9,0.3-1.4,1.1-1.4,2.2c0,5,0,10.1,0,15.1c0,1.3,0.5,2.2,1.7,2.7c0.3,0,0.6,0,0.9,0c1-0.4,1.7-1,1.7-2.1
		c0-5.3,0-10.7,0-16c0-0.1,0-0.1,0-0.2C39.3,35.3,37.9,34.5,36.5,34.9z"
        />
        <path
          d="M30.6,46.5c0-1.5,0-3,0-4.6c0-1.5-1.5-2.5-2.9-2c-0.9,0.3-1.4,1.1-1.4,2.2c0,3.4,0,6.8,0,10.3c0,1.3,0.5,2.2,1.7,2.7
		c0.3,0,0.6,0,0.9,0c1.3-0.4,1.8-1.3,1.7-2.7C30.6,50.4,30.6,48.4,30.6,46.5z"
        />
        <path
          d="M20.4,42.1c-1.4-0.4-2.8,0.6-2.8,2.2c0,2.7,0,5.3,0,8c0,1.3,0.4,2.3,1.7,2.7c0.3,0,0.6,0,0.9,0c1-0.4,1.7-1,1.7-2.1
		c0-3,0-5.9,0-8.9C21.9,43.1,21.3,42.4,20.4,42.1z"
        />
        <path
          d="M4.3,50.6c0-2.1,0-4.2,0-6.3c0-0.9-0.4-1.5-1.1-1.9C2.5,42,1.7,42,1,42.5c-0.4,0.3-0.7,0.9-1,1.4c0,3.2,0,6.3,0,9.5
		c0.3,0.9,0.9,1.4,1.7,1.7c0.3,0,0.6,0,0.9,0c1.3-0.4,1.8-1.4,1.7-2.7C4.3,51.7,4.3,51.2,4.3,50.6z"
        />
        <path
          d="M12.1,42.4c-1.5-0.9-3.2,0.1-3.3,1.9c0,2.7,0,5.3,0,8c0,1.3,0.5,2.2,1.7,2.7c0.3,0,0.6,0,0.9,0c1-0.4,1.7-1,1.7-2.1
		c0-2.9,0-5.8,0-8.7C13.1,43.5,12.7,42.8,12.1,42.4z"
        />
      </g>
    </svg>
  );
}

SupportIcon.defaultProps = {
  height: 60,
  width: 60,
};

export default SupportIcon;
